import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useFetch } from '../../hooks/useFetch';
import { QrApiResponseType } from '../../../../types/QrApiResponseType';
import useSelector from '../../redux/typedHooks';
import { LoaderPage } from '../LoaderPage';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';
import { NotFound } from '../errors/NotFound';

interface Props {
  match: { params: { qrId: string } };
}

export const QrLandingPage = ({
  match: {
    params: { qrId },
  },
}: Props) => {
  const { apimBaseUrl, apimContentHub, apimNafNoApi } = useSelector((state) => state.application);

  const { data, error } = useFetch<QrApiResponseType>(
    qrId && apimBaseUrl && apimContentHub ? { url: `${apimBaseUrl}/${apimNafNoApi}/qr/${qrId}` } : null,
  );

  const href = useDocumentUrlWithNode(
    data?.internalLink?.referenceComponent,
    data?.internalLink?.anchorId,
    data?.internalLink?.queryParams,
  );

  const history = useHistory();

  useEffect(() => {
    if (data) {
      if (href && history) {
        history.replace(href);
      }
      if (data?.url) {
        window.location.href = data.url;
      }
    }
  }, [href, data, history]);

  if (!data) {
    return <LoaderPage />;
  }

  if (error) {
    return <NotFound />;
  }
  return null;
};
